import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import PlanElectionTable from './PlanElectionTable';
import PlanElectionForm from './PlanElectionForm';
import CensusNamesForm from './CensusNamesForm';
import PlanNames from './PlanNames';
import PlanNamesForm from './PlanNamesForm';
import { addSnackbar as addMessage } from '../../shared/components/snackbar/snackbarReducer';
import { RootState } from '../../shared/types/types';
import { TPA, PlanElection, MasterPlan, MasterPlanErrors } from './types/plans';
import { zeroPlanElection } from './plansReducer';
import { PlanActionTypes } from './types/actions';
import {
  savePlanAndElectionUpdates,
  updateMasterCensusName,
  getPlans,
  updateMasterPlan,
  addPlanElection,
  updatePlanElection,
  removeSelectedElection,
  removeNewPlanAndTPA,
} from './plansActions';

const useStyles = makeStyles({
  root: {
    width: '780px',
    margin: '20px',
    minHeight: '350px',
    height: 'min-content',
    overflow: 'auto',
    padding: '.5rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
  },
  errorCard: {
    textAlign: 'center',
    marginTop: '100px',
  },
  alertText: {
    color: 'red',
    fontSize: '12px',
    textAlign: 'left',
  },
  loading: {
    height: '90%',
    alignItems: 'center',
  },
  bottom: {
    display: 'flex',
  },
});

type ParentProps = {
  masterPlan: MasterPlan;
  planElections: Record<number | string, PlanElection>;
  isLoading: boolean;
  isError: boolean;
  selectedElectionID: string;
  handleClose?: () => void;
  setOpenSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  isNewPlan: boolean;
};

type StateProps = {
  allTPAs: Record<number, TPA>;
};

const mapStateToProps = ({ plans }: RootState): StateProps => {
  return {
    allTPAs: plans.allTPAs,
  };
};

const mapDispatchToProps = {
  savePlanAndElectionUpdates,
  getPlans,
  updateMasterPlan,
  updateMasterCensusName,
  addPlanElection,
  updatePlanElection,
  removeSelectedElection,
  addMessage,
  removeNewPlanAndTPA,
};

type DispatchProps = {
  savePlanAndElectionUpdates: (
    masterPlan: MasterPlan,
    PlanElections: Record<number | string, PlanElection>,
    isNewPlan: boolean
  ) => void;
  getPlans: () => void;
  updateMasterCensusName: (masterCensus: string[], masterPlanID: number) => PlanActionTypes;
  addPlanElection: (planElection: PlanElection, FEUniqueId: number) => PlanActionTypes;
  updatePlanElection: (planElection: PlanElection, sixDegPlanID: number) => PlanActionTypes;
  removeSelectedElection: () => PlanActionTypes;
  addMessage: (message: string) => void;
  removeNewPlanAndTPA: () => void;
};

type Props = ParentProps & StateProps & DispatchProps;

export function PlanCard({
  masterPlan,
  planElections,
  allTPAs,
  isLoading,
  isError,
  savePlanAndElectionUpdates,
  removeSelectedElection,
  addMessage,
  removeNewPlanAndTPA,
  handleClose,
  isNewPlan,
  setOpenSuccess,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const serializePlanElection = (planElection: PlanElection): PlanElection => {
    // Define the default values from zeroPlanElection
    const serializedPlanElection: PlanElection = {
      ...zeroPlanElection(planElection.masterplanid), // Start with default structure
      ...planElection, // Override with any provided fields (values from the current planElection)
    };

    // Ensure proplus is set to false if it's undefined or an empty string
    serializedPlanElection.proplus =
      serializedPlanElection.proplus === '' || serializedPlanElection.proplus === undefined
        ? false
        : serializedPlanElection.proplus;

    // Handle any null or undefined values - only non-null values should persist
    const filteredPlanElection = Object.fromEntries(
      Object.entries(serializedPlanElection).filter(
        ([key, value]) => key === 'proplus' || (value !== null && value !== undefined) // Keep 'proplus' regardless
      )
    );

    return filteredPlanElection as PlanElection;
  };

  const getAllPlanElections = () => {
    if (masterPlan.masterPlanID || 0) {
      return Object.fromEntries(
        Object.entries(planElections)
          .filter(([_, election]) => election.masterplanid === masterPlan.masterPlanID)
          .map(([key, election]) => [key, serializePlanElection(election)])
      );
    }

    return {};
  };

  const [localMasterPlan, setLocalMasterPlan] = useState(masterPlan);
  const [localPlanElections, setLocalPlanElections] = useState(getAllPlanElections());
  const [selectedElectionID, setSelectedElectionID] = useState('');

  const [editMode, setEditMode] = useState(isNewPlan);
  const [isNewPlanElection, setIsNewPlanElection] = useState(false);
  const [openPlanElectionDialog, setOpenPlanElectionDialog] = useState(false);
  const [expandDetails, setExpandDetails] = useState(false);
  const [planElectionErrorText, setPlanElectionErrorText] = useState('');
  const noErrors: MasterPlanErrors = {
    tpa: '',
    masterPlanName: '',
    sfAccountID: '',
    status: '',
    masterCensus: '',
    planSelection: '',
    planElection: '',
  };

  const [planErrors, setPlanErrors] = useState(noErrors);

  useEffect(() => {
    setLocalMasterPlan(masterPlan);
    setLocalPlanElections(getAllPlanElections());
  }, [planElections, masterPlan]);

  const updateLocalPlanElection = (updatedElection: PlanElection) => {
    const key =
      updatedElection.sixDegPlanID !== 0 && updatedElection.FEUniqueId === ''
        ? updatedElection.sixDegPlanID
        : updatedElection.FEUniqueId;

    setLocalPlanElections(prev => ({
      ...prev,
      [key]: updatedElection,
    }));
  };

  const updateLocalMasterPlan = (updatedFields: Partial<MasterPlan>) => {
    setLocalMasterPlan(prev => ({
      ...prev,
      ...updatedFields,
    }));

    // Check if planSelection exists in updatedFields
    if (updatedFields.hasOwnProperty('planSelection')) {
      const isPlanSelectionTrue = updatedFields.planSelection;

      // Find the live plan elections based on stageName
      const livePlanElections = Object.values(localPlanElections).filter(
        election => election.stagename === 'Live Plan'
      );

      if (isPlanSelectionTrue) {
        // Clear censusnames when planSelection is true
        livePlanElections.forEach(election => {
          const updatedElection = {
            ...election,
            censusnames: [], // Clear censusnames
            bypasseligibility: false, // Reset bypasseligibility
          };
          updateLocalPlanElection(updatedElection);
        });
      } else {
        // Clear subPlanID when planSelection is false
        livePlanElections.forEach(election => {
          const updatedElection = {
            ...election,
            subPlanID: [], // Remove subPlanID
          };
          updateLocalPlanElection(updatedElection);
        });
      }
    }
  };

  const deleteLocalPlanElection = (FEUniqueId: string) => {
    setLocalPlanElections(prev => {
      const { [FEUniqueId]: _, ...rest } = prev;
      return rest;
    });
  };

  const handlePlanElectionSave = (uniqueIdentifier: string | number): void => {
    const currentElection = localPlanElections[uniqueIdentifier];
    const isValueEmpty = (value: any) => value === '' || value === undefined || value === 0;
    setPlanElectionErrorText('');

    // const validateStartDateConflict = () => {
    //   if (currentElection.stagename !== 'Live Plan') {
    //     return false;
    //   }

    //   for (const electionId in localPlanElections) {
    //     // Ensure the property belongs to the object, not its prototype
    //     if (!localPlanElections.hasOwnProperty(electionId)) continue;

    //     const election = localPlanElections[electionId];

    //     if (election.FEUniqueId === currentElection.FEUniqueId) {
    //       continue;
    //     }

    //     if (election.stagename === 'Live Plan') {
    //       if (election.startdate === currentElection.startdate) {
    //         setPlanElectionErrorText('Start date conflicts with another active election.');
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // };

    const validateCensusNamesConflict = () => {
      if (currentElection.stagename !== 'Live Plan') {
        return false;
      }

      for (const electionId in localPlanElections) {
        if (!localPlanElections.hasOwnProperty(electionId)) continue;

        const election = localPlanElections[electionId];

        if (election.FEUniqueId === currentElection.FEUniqueId) {
          continue;
        }

        if (election.stagename === 'Live Plan') {
          const conflictCensusNames = currentElection.censusnames?.some(name =>
            election.censusnames?.includes(name)
          );
          if (conflictCensusNames) {
            setPlanElectionErrorText('Census names conflict with another active election.');
            return true;
          }
        }
      }
      return false;
    };

    const validatePlanNameConflict = () => {
      if (currentElection.stagename !== 'Live Plan') {
        return false;
      }

      for (const electionId in localPlanElections) {
        if (!localPlanElections.hasOwnProperty(electionId)) continue;

        const election = localPlanElections[electionId];

        if (election.FEUniqueId === currentElection.FEUniqueId) {
          continue;
        }

        if (election.stagename === 'Live Plan') {
          if (election.planname === currentElection.planname) {
            setPlanElectionErrorText('Plan name conflicts with another active election.');
            return true;
          }
        }
      }
      return false;
    };

    const validateSubplanIdConflict = () => {
      if (currentElection.stagename !== 'Live Plan') {
        return false;
      }

      for (const electionId in localPlanElections) {
        if (!localPlanElections.hasOwnProperty(electionId)) continue;

        const election = localPlanElections[electionId];

        if (election.FEUniqueId === currentElection.FEUniqueId) {
          continue;
        }

        if (election.stagename === 'Live Plan') {
          const conflictSubPlanIds = currentElection.subPlanID?.some(subplan =>
            election.subPlanID?.includes(subplan)
          );
          if (conflictSubPlanIds) {
            setPlanElectionErrorText('Subplan ID conflicts with another active election.');
            return true;
          }
        }
      }
      return false;
    };

    if (validateCensusNamesConflict()) {
      return;
    }

    if (validatePlanNameConflict()) {
      return;
    }

    if (validateSubplanIdConflict()) {
      return;
    }

    /* eslint-disable guard-for-in */
    const checkLivePlanElectionsCount = () => {
      let livePlanCount = 0;

      for (const electionId in localPlanElections) {
        const election = localPlanElections[electionId];
        if (election.stagename === 'Live Plan') {
          livePlanCount += 1;
        }
      }

      return livePlanCount;
    };

    // Check if there is only one live plan election
    const livePlanCount = checkLivePlanElectionsCount();

    if (livePlanCount === 1) {
      setLocalMasterPlan(prevMasterPlan => ({
        ...prevMasterPlan,
        sixDegTPAID: currentElection.sixdegtpaid,
      }));
    }

    if (currentElection.stagename === '') {
      setPlanElectionErrorText('Stage name must be set');
      return;
    }

    if (
      livePlanCount > 1 &&
      currentElection.stagename === 'Live Plan' &&
      localMasterPlan.sixDegTPAID !== currentElection.sixdegtpaid
    ) {
      setPlanElectionErrorText('Active Plan must have the same TPA as the Master Plan.');
      return;
    }

    if (currentElection.planname === '') {
      setPlanElectionErrorText('Election Name must not be empty.');
      return;
    }

    if (currentElection.startdate === '') {
      setPlanElectionErrorText('Must set plan start date.');
      return;
    }
    if (currentElection.sixdegtpaid === 0) {
      setPlanElectionErrorText('Must set TPA.');
      return;
    }

    if (currentElection.planid.length < 3) {
      setPlanElectionErrorText('Plan ID must be 3 or more characters.');
      return;
    }
    if (currentElection.planname.length < 3) {
      setPlanElectionErrorText('Election name must be 3 or more characters.');
      return;
    }

    if (currentElection.proplus === '' || currentElection.proplus === undefined) {
      setPlanElectionErrorText('Pro Plus is required.');
      return;
    }

    if (currentElection.invoicingtype !== 'none' && currentElection.percentsavings <= 0) {
      setPlanElectionErrorText('If an invoicing type is chosen percent value is required.');
      return;
    }

    if (currentElection.subPlanID?.includes(currentElection.planid)) {
      setPlanElectionErrorText('Sub Plan ID should not be same as Plan ID.');
      return;
    }

    // if (
    //   currentElection.stagename === 'Run Out' &&
    //   !currentElection.indefiniterunout &&
    //   !currentElection.repriceforrunoutperiod
    // ) {
    //   setPlanElectionErrorText(
    //     'Either Indefinitely reprice claims with DOS within contracted period or Reprice claims according to run out period should be selected.'
    //   );
    //   return;
    // }

    if (
      currentElection.invoicingtype === '% Billed' ||
      currentElection.invoicingtype === '% Saving'
    ) {
      if (!currentElection.professionalinvoicing && !currentElection.facilityinvoicing) {
        setPlanElectionErrorText(
          '837 Invoicing Requires professionalinvoicing or facilityinvoicing to be Selected.'
        );
        return;
      }
    }

    // if (currentElection.stagename === 'Run Out' && currentElection.repriceforrunoutperiod) {
    //   if (currentElection.runoutenddate === '') {
    //     setPlanElectionErrorText('Run out end date is required.');
    //     return;
    //   }
    // }

    if (
      isValueEmpty(currentElection.facilitymultiplier) &&
      isValueEmpty(currentElection.drugmultiplier) &&
      isValueEmpty(currentElection.anesthesiamultiplier) &&
      isValueEmpty(currentElection.professionalmultiplier)
    ) {
      setPlanElectionErrorText(
        'At least one of the following fields must have a value: facilitymultiplier, drugmultiplier, anesthesiamultiplier, or professionalmultiplier.'
      );
      return;
    }

    if (
      currentElection.tbcthreshold &&
      (currentElection.threshold === 0 ||
        currentElection.threshold === undefined ||
        currentElection.threshold === null ||
        Number.isNaN(currentElection.threshold))
    ) {
      setPlanElectionErrorText('TBC Threshold is required.');
      return;
    }

    if (currentElection.stagename === 'Termed' && currentElection.termdate === '01/01/9999') {
      setPlanElectionErrorText('Plan Term Date Required.');
      return;
    }

    if (currentElection.tbcthreshold && isValueEmpty(currentElection.threshold)) {
      setPlanElectionErrorText('Threshold is required, when tbcthreshold is selected.');
      return;
    }

    if (currentElection.displaymedivimessage && currentElection.medivimessage === '') {
      setPlanElectionErrorText('Medivimessage is required, when displaymedivimessage is selected.');
      return;
    }

    if (
      !localMasterPlan.planSelection &&
      currentElection.censusnames &&
      currentElection.censusnames.length <= 0
    ) {
      setPlanElectionErrorText('Census Name is required.');
      return;
    }

    if (
      localMasterPlan.planSelection &&
      currentElection.subPlanID &&
      currentElection.subPlanID.length <= 0
    ) {
      setPlanElectionErrorText('Sub Plan ID is required.');
      return;
    }

    updateLocalPlanElection({
      ...currentElection,
      facilitymultiplier: Number(currentElection.facilitymultiplier),
      drugmultiplier: Number(currentElection.drugmultiplier),
      anesthesiamultiplier: Number(currentElection.anesthesiamultiplier),
      professionalmultiplier: Number(currentElection.professionalmultiplier),
      percentsavings: Number(currentElection.percentsavings),
      threshold: Number(currentElection.threshold),
    });

    setPlanElectionErrorText('');

    setOpenPlanElectionDialog(false);
  };

  const handleSave = (): void => {
    const newErrors = { ...noErrors };

    if (!localMasterPlan.masterPlanName || localMasterPlan.masterPlanName === '') {
      newErrors.masterPlanName = 'Plan Name is required';
    }
    if (!localMasterPlan.sfAccountID || localMasterPlan.sfAccountID === '') {
      newErrors.sfAccountID = 'SF Account ID is required';
    }
    if (!localMasterPlan.status || localMasterPlan.status === '') {
      newErrors.status = 'Status is required';
    }
    if (
      localMasterPlan.planSelection === true &&
      (!localMasterPlan.masterCensus || localMasterPlan.masterCensus?.length === 0)
    ) {
      newErrors.masterCensus = 'Master Census is required';
    }

    if (!localPlanElections || Object.keys(localPlanElections || []).length === 0) {
      newErrors.planElection = 'At least one plan election is required';
    }

    // Check if planSelection is false
    if (!localMasterPlan.planSelection) {
      const missingCensusNames = Object.values(localPlanElections).some(
        election =>
          election.stagename === 'Live Plan' &&
          (!election.censusnames || election.censusnames.length === 0)
      );

      if (missingCensusNames) {
        newErrors.planElection = 'Each active plan election should include the census names.';
      }
    }

    // Check if planSelection is true
    if (localMasterPlan.planSelection) {
      const missingSubPlanIDs = Object.values(localPlanElections).some(
        election =>
          election.stagename === 'Live Plan' &&
          (!election.subPlanID || election.subPlanID.length === 0)
      );

      if (missingSubPlanIDs) {
        newErrors.planElection = 'Each active plan election should include the subPlanID.';
      }
    }

    setPlanErrors(newErrors);
    if (Object.values(newErrors).some(value => value !== '')) return;

    setEditMode(false);
    savePlanAndElectionUpdates(localMasterPlan, localPlanElections, isNewPlan);
    if (isNewPlan) {
      setOpenSuccess(true);
    }
    if (handleClose) {
      handleClose();
    }
  };

  const handleCancel = (): void => {
    if (handleClose) {
      handleClose();
    }
    if (isNewPlan) {
      removeNewPlanAndTPA();
    } else {
      setEditMode(false);
    }
  };

  const handlePlanElectionClose = (): void => {
    if (localPlanElections[selectedElectionID] && isNewPlanElection) {
      const updatedElections = { ...localPlanElections };
      delete updatedElections[selectedElectionID];
      setLocalPlanElections(updatedElections);
    } else if (selectedElectionID) {
      setLocalPlanElections(prevElections => ({
        ...prevElections,
        [selectedElectionID]: serializePlanElection(planElections[selectedElectionID]),
      }));
    }
    setOpenPlanElectionDialog(false);
  };

  if (isLoading) {
    return (
      <Card className={classes.root}>
        <LoadingSpinner containerClassName={classes.loading} />
      </Card>
    );
  }

  // if (isError) {
  //   return (
  //     <Card className={classes.root}>
  //       <div className={classes.errorCard}>
  //         <Typography>The was an error involving this plan...</Typography>
  //         <IconButton color="secondary" onClick={handleCancel}>
  //           Reload <RefreshIcon />
  //         </IconButton>
  //       </div>
  //     </Card>
  //   );
  // }

  // if (isDeleted) {
  //   return (
  //     <Card className={classes.root}>
  //       <div className={classes.errorCard}>
  //         <Typography>Plan Deleted successfully</Typography>
  //         {/* <IconButton color="secondary" onClick={handleCancel}>
  //           Reload <RefreshIcon />
  //         </IconButton> */}
  //       </div>
  //     </Card>
  //   );
  // }

  if (editMode) {
    return (
      <>
        <Card className={classes.root}>
          <CardContent>
            <PlanNamesForm
              localMasterPlan={localMasterPlan}
              planElections={localPlanElections}
              updateMasterPlan={updateLocalMasterPlan}
              allTPAs={allTPAs}
              masterPlanErrors={planErrors}
              setPlanErrors={setPlanErrors}
              handleSnackBar={addMessage}
            />
            <CensusNamesForm
              localMasterPlan={localMasterPlan}
              setLocalMasterPlan={setLocalMasterPlan}
              masterPlanErrors={planErrors}
              setPlanErrors={setPlanErrors}
            />
            <PlanElectionTable
              masterPlanID={localMasterPlan.masterPlanID}
              planSelection={localMasterPlan.planSelection}
              planElections={localPlanElections}
              updateLocalPlanElection={updateLocalPlanElection}
              deleteLocalPlanElection={deleteLocalPlanElection}
              setSelectedElectionID={setSelectedElectionID}
              setOpenPlanElectionDialog={setOpenPlanElectionDialog}
              removeSelectedElection={removeSelectedElection}
              setIsNewPlanElection={setIsNewPlanElection}
              editMode={editMode}
              expandDetails={expandDetails}
            />
            <PlanElectionForm
              localMasterPlan={localMasterPlan}
              updateLocalPlanElection={updateLocalPlanElection}
              planElection={
                selectedElectionID && localPlanElections[selectedElectionID]
                  ? localPlanElections[selectedElectionID]
                  : zeroPlanElection(masterPlan.masterPlanID)
              }
              handlePlanElectionSave={handlePlanElectionSave}
              openPlanElectionDialog={openPlanElectionDialog}
              setOpenPlanElectionDialog={setOpenPlanElectionDialog}
              allTPAs={allTPAs}
              planElectionErrorText={planElectionErrorText}
              setPlanElectionErrorText={setPlanElectionErrorText}
              handleCancel={handlePlanElectionClose}
              isNewPlanElection={isNewPlanElection}
              handleSnackBar={addMessage}
            />
            <CardActions>
              <Button size="large" variant="contained" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>

              <Button size="large" variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </CardActions>
            {planErrors.planElection !== '' && (
              <Typography className={classes.alertText}>{planErrors.planElection}</Typography>
            )}
          </CardContent>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <PlanNames
            masterPlanID={masterPlan.masterPlanID}
            masterPlanName={masterPlan.masterPlanName}
            sfAccountID={masterPlan.sfAccountID}
            planSelection={masterPlan.planSelection}
            status={masterPlan.status}
            censusNames={masterPlan.masterCensus || []}
            planElections={localPlanElections}
            allTPAs={allTPAs}
            sixDegTPAID={masterPlan.sixDegTPAID}
            expandDetails={expandDetails}
          />
          <PlanElectionTable
            masterPlanID={masterPlan.masterPlanID}
            planElections={planElections}
            planSelection={masterPlan.planSelection}
            deleteLocalPlanElection={deleteLocalPlanElection}
            editMode={editMode}
            expandDetails={expandDetails}
            updateLocalPlanElection={updateLocalPlanElection}
            setSelectedElectionID={setSelectedElectionID}
            setOpenPlanElectionDialog={setOpenPlanElectionDialog}
            removeSelectedElection={removeSelectedElection}
            setIsNewPlanElection={setIsNewPlanElection}
          />
        </CardContent>
        <div className={classes.bottom}>
          <CardActions>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={(): void => setEditMode(true)}
            >
              Edit
            </Button>
          </CardActions>
          <CardActions>
            <Button
              size="large"
              color="primary"
              onClick={(): void => setExpandDetails(!expandDetails)}
            >
              Details
              {expandDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          </CardActions>
        </div>
      </Card>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCard);
