import React from 'react';

import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import { colors } from '@apps/shared/src/style';
import { inflateString } from '@apps/shared/src/inflators';
import { ContractPlan } from '../../../contracts/types/contracts';
import { Plan } from '../../../repricing/types/repricingTypes';

const useStyles = makeStyles({
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'normal',
    alignItems: 'flex-start',
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  name: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  planID: {
    fontSize: '.85em',
    color: colors.greyDark,
  },
});

type Props = {
  option: ContractPlan | Plan;
  index: number;
  highlightedIndex: number;
  getItemProps: (obj: object) => void;
};
export default function PlanOption({
  option,
  index,
  highlightedIndex,
  getItemProps,
}: Props): JSX.Element {
  const classes = useStyles();
  const { planName, policyNum, planID, subPlanID } = option;
  const key = `${planName}-${planID || policyNum}`;
  return (
    <MenuItem
      {...getItemProps({
        item: option,
        key,
        component: 'div',
        dense: true,
        selected: highlightedIndex === index,
        className: classes.menuItem,
      })}
    >
      <div className={classes.firstRow}>
        <div className={classes.name}>
          <Typography variant="body2" noWrap>
            <strong>{inflateString(planName)}</strong>
          </Typography>
        </div>
      </div>
      {(policyNum || planID) && (
        <Typography variant="body2" className={classes.planID}>
          Policy Number: {policyNum || planID}
        </Typography>
      )}
      {subPlanID && subPlanID.length > 0 && (
        <Typography variant="body2" className={classes.planID}>
          Sub Plan: {subPlanID.join(', ')}
        </Typography>
      )}
    </MenuItem>
  );
}
