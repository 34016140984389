import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { colors } from '@apps/shared/src/style';
import { PlanElection } from './types/plans';
import { PlanActionTypes } from './types/actions';
import { zeroPlanElection } from './plansReducer';
import { validArray } from '../../shared/typeChecks';

const useStyles = makeStyles({
  scrollContainer: {
    height: '13rem',
    padding: '1rem 1.5rem',
    backgroundColor: '#eeeeee',
    borderRadius: '2rem',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '10px',
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb ': {
      background: 'grey',
      borderRadius: '10px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  singleElection: {
    backgroundColor: 'white',
    padding: '.8rem',
    marginBottom: '.5rem',
    borderRadius: '1rem',
  },
  electionContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  stageName: {
    backgroundColor: '#c5c2c2',
    borderRadius: '4rem',
    width: '5rem',
    padding: '0.5rem',
    fontWeight: 500,
    display: 'grid',
    placeItems: 'center',
  },
  head: {
    color: '#c5c2c2',
  },
  addButton: {
    backgroundColor: colors.green,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.greenMuted,
    },
    minWidth: '0px',
    padding: '0',
    marginLeft: '.5rem',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    padding: '0.5rem 1rem',
    borderBottom: '1px solid #ddd8d8',
    justifyContent: 'center',
    alignItems: 'center',
  },
  medivimessage: {},
  noElectionMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: '#666',
    fontSize: '1.2rem',
  },
  deleteButton: {
    color: colors.white,
    backgroundColor: colors.red,
    '&:hover': {
      backgroundColor: colors.redScarlet,
    },
    padding: '0px',
    minWidth: '0px',
  },
  bottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

type ParentProps = {
  masterPlanID: number;
  planSelection: boolean;
  setIsNewPlanElection: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPlanElectionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedElectionID: React.Dispatch<React.SetStateAction<string>>;
  removeSelectedElection: () => void;
  updateLocalPlanElection: (updatedElection: PlanElection) => void;
  deleteLocalPlanElection: (FEUniqueID: string) => void;
  planElections: Record<number | string, PlanElection>;
  editMode: boolean;
  expandDetails: boolean;
};

type Props = ParentProps;

export default function PlanElectionTable({
  masterPlanID,
  planSelection,
  planElections,
  setOpenPlanElectionDialog,
  setSelectedElectionID,
  deleteLocalPlanElection,
  removeSelectedElection,
  updateLocalPlanElection,
  setIsNewPlanElection,
  editMode,
  expandDetails,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleEdit = (FEUniqueId: string, sixDegPlanID: number) => {
    if (sixDegPlanID && sixDegPlanID !== 0) {
      setSelectedElectionID(sixDegPlanID);
    } else {
      setSelectedElectionID(FEUniqueId);
    }
    setOpenPlanElectionDialog(true);
  };

  const getBorderColor = (stagename: string, editMode: boolean, expandDetails: boolean) => {
    const baseStyle = {
      borderLeft: '5px solid red',
      width: editMode || expandDetails ? 'auto' : '80%',
      marginLeft: editMode || expandDetails ? '' : '5rem',
      fontSize: editMode || expandDetails ? '' : '.9rem',
      backgroundColor: editMode || expandDetails ? '' : '#eeeeee',
    };

    if (stagename === 'Live Plan') {
      return { ...baseStyle, borderLeft: '5px solid #0bbe0b' };
    }
    // if (stagename === 'Run Out') {
    //   return { ...baseStyle, borderLeft: '5px solid rgb(247, 200, 43)' };
    // }
    return baseStyle;
  };

  const getPlanElections = (
    planElections: Record<number | string, PlanElection>,
    masterPlanID: number
  ): any[] => {
    // Define a custom sort order for the stage names
    const stageOrder = {
      'Live Plan': 1,
      Termed: 3,
    };

    return Object.values(planElections || [])
      .filter((election: PlanElection) => election.masterplanid === masterPlanID)
      .sort((a: PlanElection, b: PlanElection) => {
        const stageA = stageOrder[a.stagename] || 4;
        const stageB = stageOrder[b.stagename] || 4;
        return stageA - stageB;
      });
  };

  const filteredElections = getPlanElections(planElections, masterPlanID);

  let safeplanElections = validArray(filteredElections);

  const handleAddElection = () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    const { v4: uuidv4 } = require('uuid');
    const UniqueId = uuidv4();
    const newPlanElection = zeroPlanElection(masterPlanID);
    const planElectionWithId = {
      ...newPlanElection,
      FEUniqueId: UniqueId,
    };

    updateLocalPlanElection(planElectionWithId);
    setSelectedElectionID(UniqueId);
    removeSelectedElection();
    setOpenPlanElectionDialog(true);
    setIsNewPlanElection(true);
  };

  useEffect(() => {
    safeplanElections = validArray(filteredElections);
  }, [planElections]);

  return (
    <>
      <div className={classes.header}>
        <Typography>Elections</Typography>
        {editMode && (
          <IconButton className={classes.addButton} onClick={() => handleAddElection()}>
            <AddCircleIcon />
          </IconButton>
        )}
      </div>

      <div
        className={classes.scrollContainer}
        style={{
          backgroundColor: !expandDetails && !editMode ? 'white' : '#eeeeee',
          padding: !expandDetails && !editMode ? '0px' : '1rem 1.5rem',
        }}
      >
        {safeplanElections.length > 0 ? (
          safeplanElections.map((row, i) => (
            <div
              key={row.planname}
              className={classes.singleElection}
              style={getBorderColor(row.stagename, editMode, expandDetails)}
            >
              {(editMode || expandDetails) && (
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div className={classes.stageName}>{row.stagename}</div>
                  {editMode && row.sixDegPlanID === 0 && (
                    <Button
                      size="large"
                      color="secondary"
                      onClick={() => deleteLocalPlanElection(row.FEUniqueId)}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              )}
              <div className={classes.electionContent}>
                <div
                  className={classes.field}
                  style={{
                    alignItems: 'start',
                    marginTop: '.5rem',
                    ...(!expandDetails && !editMode ? { borderBottom: 'none' } : {}),
                  }}
                >
                  <div className={classes.head}>Election Name</div>
                  <div>{row.planname}</div>
                </div>

                <div
                  className={classes.field}
                  style={!expandDetails && !editMode ? { borderBottom: 'none' } : {}}
                >
                  <div className={classes.head}>Election ID</div>
                  <div>{row.sixDegPlanID !== 0 ? row.sixDegPlanID : '-'}</div>
                </div>
                <div
                  className={classes.field}
                  style={!expandDetails && !editMode ? { borderBottom: 'none' } : {}}
                >
                  <div className={classes.head}>Start Date</div>
                  <div>{row.startdate ? row.startdate : '-'}</div>
                </div>
                <div
                  className={classes.field}
                  style={!expandDetails && !editMode ? { borderBottom: 'none' } : {}}
                >
                  <div className={classes.head}>Term Date</div>
                  <div>{row.termdate ? row.termdate : '-'}</div>
                </div>
                {(editMode || expandDetails) && (
                  <div className={classes.field}>
                    <div className={classes.head}>Plan ID</div>
                    <div>{row.planid}</div>
                  </div>
                )}
              </div>
              {(editMode || expandDetails) && <br />}
              {(editMode || expandDetails) && (
                <div className={classes.electionContent}>
                  <div className={classes.field}>
                    <div className={classes.head}>Facility</div>
                    <div>{row.facilitymultiplier ? row.facilitymultiplier : '-'}</div>
                  </div>
                  <div className={classes.field}>
                    <div className={classes.head}>Professional</div>
                    <div>{row.professionalmultiplier ? row.professionalmultiplier : '-'}</div>
                  </div>
                  <div className={classes.field}>
                    <div className={classes.head}>Drug</div>
                    <div>{row.drugmultiplier ? row.drugmultiplier : '-'}</div>
                  </div>
                  <div className={classes.field}>
                    <div className={classes.head}>Anesthesia</div>
                    <div>{row.anesthesiamultiplier ? row.anesthesiamultiplier : '-'}</div>
                  </div>
                  {row.stagename === 'Live Plan' && !planSelection && (
                    <div className={classes.field}>
                      <div className={classes.head}>Census Name(s)</div>
                      <div>{row.censusnames ? row.censusnames.join(', ') : ''}</div>
                    </div>
                  )}

                  {row.stagename === 'Live Plan' && planSelection && (
                    <div className={classes.field}>
                      <div className={classes.head}>Sub Plan ID(s)</div>
                      <div>{row.subPlanID ? row.subPlanID.join(', ') : ''}</div>
                    </div>
                  )}

                  {row.stagename === 'Termed' && (
                    <div className={classes.field}>
                      <div className={classes.head}>
                        {row.censusnames?.length ? 'Census Name(s)' : 'Sub Plan ID(s)'}
                      </div>
                      <div>
                        {row.censusnames?.length
                          ? row.censusnames.join(', ')
                          : row.subPlanID?.join(', ') || ''}
                      </div>
                    </div>
                  )}

                  {(editMode || expandDetails) && (
                    <div className={classes.field}>
                      <div className={classes.head}>ProPlus</div>
                      <div>{row.proplus ? 'Yes' : 'No'}</div>
                    </div>
                  )}
                  {(editMode || expandDetails) && (
                    <div className={classes.field}>
                      <div className={classes.head}>837 invoice</div>
                      <div>{row.invoicingtype ? row.invoicingtype : '-'}</div>
                    </div>
                  )}
                  {(editMode || expandDetails) &&
                    (row.invoicingtype === '% Saving' || row.invoicingtype === '% Billed') && (
                      <div className={classes.field}>
                        <div className={classes.head}>
                          Percent&nbsp;of&nbsp;
                          {row.invoicingtype === '% Saving' ? 'Savings' : 'Billed'}&nbsp;Fee
                        </div>
                        <div>{row.percentsavings ? row.percentsavings : '-'}</div>
                      </div>
                    )}
                  {(editMode || expandDetails) && row.invoicingtype !== 'none' && (
                    <div className={classes.field}>
                      <div className={classes.head}>Facility Invoicing</div>
                      <div>
                        <input type="checkbox" checked={row.facilityinvoicing} readOnly />
                      </div>
                    </div>
                  )}
                  {(editMode || expandDetails) && row.invoicingtype !== 'none' && (
                    <div className={classes.field}>
                      <div className={classes.head}>Professional Invoicing</div>
                      <div>
                        <input type="checkbox" checked={row.professionalinvoicing} readOnly />
                      </div>
                    </div>
                  )}
                  {(editMode || expandDetails) && !planSelection && (
                    <div className={classes.field}>
                      <div className={classes.head}>Bypass Eligibility</div>
                      <div>
                        <input
                          type="checkbox"
                          checked={row.bypasseligibility ? !!row.bypasseligibility : false}
                          readOnly
                        />
                      </div>
                    </div>
                  )}

                  {(editMode || expandDetails) && (
                    <div className={classes.field}>
                      <div className={classes.head}>Medivi Access</div>
                      <div>{row.mediviplanaccess ? row.mediviplanaccess : '-'}</div>
                    </div>
                  )}
                  {(editMode || expandDetails) && (
                    <div className={classes.field}>
                      <div className={classes.head}>Group ID on Card</div>
                      <div>{row.groupidoncard ? row.groupidoncard : '-'}</div>
                    </div>
                  )}
                  {/* {(editMode || expandDetails) && row.stagename === 'Run Out' && (
                    <div className={classes.field}>
                      <div className={classes.head}>Run Out Type</div>
                      <div>
                        {row.indefiniterunout
                          ? 'Indefinitely reprice claims with DOS within contracted period'
                          : ''}
                        {!row.indefiniterunout && !row.repriceforrunoutperiod && '-'}
                      </div>
                      <div>
                        {row.repriceforrunoutperiod
                          ? 'Reprice claims according to run out period'
                          : ''}
                      </div>
                    </div>
                  )}

                  {(editMode || expandDetails) && row.stagename === 'Run Out' && (
                    <div className={classes.field}>
                      <div className={classes.head}>Run Out End Date</div>
                      <div>{row.runoutenddate ? row.runoutenddate : '-'}</div>
                    </div>
                  )} */}
                  {(editMode || expandDetails) && (
                    <div className={classes.field}>
                      <div className={classes.head}>Run In</div>
                      <div>
                        <input type="checkbox" checked={row.runin ? !!row.runin : false} readOnly />
                      </div>
                    </div>
                  )}
                  {(editMode || expandDetails) && row.tbcthreshold && (
                    <div className={classes.field}>
                      <div className={classes.head}>
                        Coding Review Threshold&nbsp;&nbsp;
                        <input
                          type="checkbox"
                          checked={row.tbcthreshold ? !!row.tbcthreshold : false}
                          readOnly
                        />
                      </div>
                      {row.tbcthreshold && <div>{row.threshold}</div>}
                    </div>
                  )}
                  {(editMode || expandDetails) && row.displaymedivimessage && (
                    <div className={classes.field}>
                      <div className={classes.head}>
                        <Typography>Display Medivi Message</Typography>&nbsp;&nbsp;
                        <input type="checkbox" checked={!!row.displaymedivimessage} readOnly />
                      </div>
                      {row.displaymedivimessage && (
                        <textarea
                          className={classes.medivimessage}
                          readOnly
                          value={row.medivimessage}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {editMode && (
                <div className={classes.bottom}>
                  <Button
                    size="large"
                    color="primary"
                    onClick={(): void => {
                      handleEdit(row.FEUniqueId, row.sixDegPlanID);
                      setIsNewPlanElection(false);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className={classes.noElectionMessage}>No Elections added</div>
        )}
      </div>
    </>
  );
}
