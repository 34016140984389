import { TermItem } from '../types/contracts';

export const termItems: Record<string, TermItem> = {
  percentCMS: 'percentCMS',
  percentBilledCharges: 'percentBilledCharges',
  perDiem: 'perDiem',
  flatRate: 'flatRate',
  caseRate: 'caseRate',
  maxAmount: 'maxAmount',
  unlistedCharges: 'unlistedCharges',
  limitedServices: 'limitedServices',
  claimType: 'claimType',
  revCode: 'revCode',
  hcpcsOrCPT: 'hcpcsOrCPT',
  extraStipulations: 'extraStipulations',
  rangeType: 'rangeType',
  startRange: 'startRange',
  endRange: 'endRange',
  rangeInclusive: 'rangeInclusive',
  comparisonType: 'comparisonType',
  costPerUnitOrVisit: 'costPerUnitOrVisit',
  percentOfASP: 'percentOfASP',
  percentOfDME: 'percentOfDME',
  percentOfWAC: 'percentOfWAC',
  percentOfAcquisition: 'percentOfAcquisition',
  dollarsPerMonth: 'dollarsPerMonth',
  dollarCF: 'dollarCF',
};

export const ruleNameLookup = {
  [termItems.percentCMS]: 'Percent of CMS',
  [termItems.percentBilledCharges]: 'Percent of Billed Charges',
  [termItems.perDiem]: 'Per Diem',
  [termItems.flatRate]: 'Flat Rate',
  [termItems.caseRate]: 'Case Rate',
  [termItems.maxAmount]: 'Max Amount',
  [termItems.costPerUnitOrVisit]: '$ Per Unit/Visit',
  [termItems.percentOfASP]: 'Percent of ASP',
  [termItems.percentOfDME]: 'Percent of DME',
  [termItems.percentOfWAC]: 'Percent of WAC',
  [termItems.percentOfAcquisition]: 'Percent of Aquisition',
  [termItems.dollarsPerMonth]: '$ Per Month',
  [termItems.dollarCF]: '$ Conversion Factor',
};
export const ruleTypeList = Object.keys(ruleNameLookup);
export const ruleTypes = ruleTypeList.map(rtl => ({ value: rtl, name: ruleNameLookup[rtl] }));

export const rangeTypes = [
  { value: 'dollars', name: 'Dollars' },
  { value: 'days', name: 'Days' },
];

export const rangeElements = [
  termItems.rangeType,
  termItems.startRange,
  termItems.endRange,
  termItems.rangeInclusive,
];

export const conditionNameLookup = {
  [termItems.unlistedCharges]: 'For All Unlisted Charges',
  [termItems.limitedServices]: 'For Limited Services',
  [termItems.claimType]: 'For Type of Claim',
  [termItems.revCode]: 'For Individual Rev Codes',
  [termItems.hcpcsOrCPT]: 'For Individual HCPCS or CPT Codes',
  [termItems.extraStipulations]: 'For Extra Stipulations',
  [termItems.rangeType]: 'For a Range of',
};
export const conditionTypeList = Object.keys(conditionNameLookup);
export const conditionTypes = conditionTypeList.map(ctl => ({
  value: ctl,
  name: conditionNameLookup[ctl],
}));
