import React, { useCallback, Component } from 'react';
import PropTypes from 'prop-types';
import axios, { AxiosError } from 'axios';
import { connect, useDispatch } from 'react-redux';
import { getAxiosRequestConfig } from '@apps/shared/src/utilities/http';

import {
  getFlatClaim,
  saveClaim,
  saveClaimAndRedirect,
  clearClaim,
  deleteClaim,
  saveClaimAndSubmit,
  initialSaveClaim,
  validateClaimBeforeFileUpload,
  checkClaimAndRedirect,
} from './rootReducer';
import {
  updateProviderValue,
  updateNPI,
  toggleAddressIsEditable,
  toggleAddressVerified,
} from './providerReducer';
import { addServiceItem, removeServiceItem, updateServiceValue } from './servicesReducer';
import { updateClaimValue, updatePolicyNumber, updateSubPlanID } from './claimsReducer';
import { updateIndivOrOrgValue } from './indivOrOrgReducer';

import ManualClaimForm from './ManualClaimForm/ManualClaimForm';
import ClaimList from '../claimList/ClaimList';
import { getClaims, getPlans, navigateQueue } from '../repricing/claimsActions';
import { addSnackbar } from '../shared/components/snackbar/snackbarReducer';
import ClaimOpenError from '../shared/components/ClaimOpenError';
import { getBaseRoute, formatTextValue, fgRepricing, fgManualClaims } from '../shared/globals';
import { claimStatusCodes, filterGroups } from '../shared/status';
import * as types from '../shared/types/propTypes';
import AppLayout from '../shared/components/layout/AppLayout';
import {
  updateServiceLocationNPI,
  updateServiceLocationProviderValue,
  toggleServiceLocationAddressIsEditable,
  toggleServiceLocationAddressVerified,
} from './serviceLocationReducer';

export class NewClaims extends Component {
  constructor(props) {
    super(props);
    const { getPlans, plans } = this.props;
    this.state = {
      currentPage: 1,
      pageSize: 25,
      selectedFile: null,
      claimType: '',
    };
    if (plans.length === 0) {
      getPlans();
    }
  }

  componentDidMount() {
    const { getClaims } = this.props;
    const { currentPage, pageSize } = this.state;
    getClaims(fgManualClaims, currentPage, pageSize);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextID = nextProps.match.params.id;
    const currentID = this.props.match.params.id;

    if (nextID !== undefined && nextID !== nextProps.selectedClaim.id) {
      if (!nextProps.invalidClaimId || currentID !== nextID) {
        this.props.getClaim(nextProps.match.params.id);
      }
    }
  }

  handleFileAndClaimTypeChange = (selectedFile, claimType) => {
    this.setState({ selectedFile, claimType });
  };

  handleTextChange = name => e => this.props.updateClaimValue(name, e.target.value.toUpperCase());
  formatTextChange = name => text => this.props.updateClaimValue(name, formatTextValue(text));
  handleDateChange = name => value => this.props.updateClaimValue(name, value);
  handleSaveClick = () => {
    const { save, initialSave, selectedClaim, match, history } = this.props;
    console.log('in handleSaveClick', selectedClaim);

    if (selectedClaim && selectedClaim.id) {
      save(match.params.id, selectedClaim);
    } else {
      initialSave(match.params.id, selectedClaim, history);
    }
  };

  handleSaveAndRepriceClick = () => {
    const { saveAndReprice, selectedClaim, match, history } = this.props;
    saveAndReprice(match.params.id, selectedClaim, history);
  };

  handleCompleteAndSubmit = () => {
    const { saveAndSubmit, selectedClaim, history } = this.props;
    saveAndSubmit(selectedClaim.id, selectedClaim, history);
  };

  handleDeleteClick = () => this.props.deleteClaim(this.props.match.params.id);
  componentWillUnmount = () => this.props.clearClaim();

  handleClaimListItemKeyPress = id => e => {
    if (e.key === 'Enter') this.handleClaimListItemSelect(id)();
  };

  handleClaimListItemSelect = id => () => this.props.history.push(`/edit-claim/${id}`);

  handleNewClaim = () => {
    this.props.clearClaim();
    this.props.history.push('/new-claim');
  };

  handleValidateClaim = id => this.props.checkClaimAndRedirect(id, this.props.history, 'validate');

  handleInvalidateClaim = id =>
    this.props.checkClaimAndRedirect(id, this.props.history, 'invalidate');

  handleFileUpload = async () => {
    const { selectedFile, claimType } = this.state;
    const { updateClaimValue, selectedClaim, showSnackbar, hasErrors } = this.props;
    console.log('in file upload:', selectedClaim);
    let validClaim = false;
    validClaim = hasErrors(selectedClaim);
    if (!validClaim) {
      try {
        const formData = new FormData();
        formData.append('formType', claimType);

        formData.append('file', selectedFile, selectedFile.name); // Append each file individually

        const uploadResult = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL || ''}/api/claim-file-upload`,
          formData,
          getAxiosRequestConfig()
        );
        if (selectedFile && uploadResult.data) {
          updateClaimValue('claimFile', uploadResult.data);
          selectedClaim.claims[0].claimFile = uploadResult.data;
          showSnackbar('Claim File Upload Successful');
          this.setState({ selectedFile: null, claimType: '' });
          return uploadResult.data;
        }
        return validClaim;
      } catch (error) {
        if (error.response) {
          showSnackbar(error.response.data);
          console.error('Error response:', error.response.data);
        } else {
          console.log(error);
          showSnackbar('Error uploading the file');
        }
        this.setState({ selectedFile: null, claimType: '' });
        return null;
      }
    }
    return validClaim;
  };

  handleCloseOwnershipError = () => this.props.history.push('/new-claim');

  handlePolicyNumChange = (subPlanID, policyNum, sixDegTPAID, sixDegPlanID) => {
    this.props.updatePolicyNumber(
      'selectedClaim.claims[0]',
      policyNum,
      sixDegTPAID,
      sixDegPlanID,
      this.props.plans
    );
    this.saveSubPlanID(subPlanID);
  };

  saveSubPlanID = subPlanID => {
    this.props.updateSubPlanID('selectedClaim.claims[0]', subPlanID);
  };

  handlePageChange = page => {
    const newBaseRoute = getBaseRoute(this.props.match.path);
    this.setState(
      prevState => ({
        ...prevState,
        currentPage: page,
      }),
      () => {
        const { currentPage, pageSize } = this.state;
        this.props.getClaims(newBaseRoute, currentPage, pageSize);
      }
    );
  };

  handlePageSizeChange = size => {
    const newBaseRoute = getBaseRoute(this.props.match.path);
    this.setState(
      prevState => ({
        ...prevState,
        pageSize: size,
      }),
      () => {
        const { currentPage, pageSize } = this.state;
        this.props.getClaims(newBaseRoute, currentPage, pageSize);
      }
    );
  };

  render() {
    const { selectedClaim, isEdit, claims, currentUser, search, history, match } = this.props;
    const { currentPage, pageSize, newOpen, selectedFile, claimType } = this.state;
    const isDataEnterer = (c, u) =>
      c.handlers && c.handlers.dataEnterer && u.userID === c.handlers.dataEnterer.id;
    const isCurrentDataEnterer = isDataEnterer(selectedClaim, currentUser);
    const claimList = claims?.filter(
      c => !(c.status === claimStatusCodes.dataEntryValidation && isDataEnterer(c, currentUser))
    );
    const baseRoute = getBaseRoute(match.path);
    const handleNavigatePrev = navigateQueue(claimList, selectedClaim.id, baseRoute, -1, history);
    const handleNavigateNext = navigateQueue(claimList, selectedClaim.id, baseRoute, 1, history);

    return (
      <AppLayout
        sidebar={
          <ClaimList
            loading={search && search.fetchingClaims}
            claimList={claimList}
            filterGroup={filterGroups.manualClaims}
            filterGroupName={fgManualClaims}
            handleNavigateNext={handleNavigateNext}
            handleNavigatePrev={handleNavigatePrev}
            handleClaimListItemKeyPress={this.handleClaimListItemKeyPress}
            handleClaimListItemSelect={this.handleClaimListItemSelect}
            selectedID={this.props.match.params.id}
            allowSearch={false}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={this.props.totalPages}
          />
        }
      >
        <ClaimOpenError
          selectedClaim={selectedClaim}
          currentUser={this.props.currentUser}
          closeOwnershipError={this.handleCloseOwnershipError}
        />
        <ManualClaimForm
          selectedClaim={selectedClaim}
          hasErrors={validateClaimBeforeFileUpload}
          handleTextChange={this.handleTextChange}
          formatTextChange={this.formatTextChange}
          handleDateChange={this.handleDateChange}
          updateProviderValue={this.props.updateProviderValue}
          updateSubscriberValue={this.props.updateSubscriberValue}
          updatePatientValue={this.props.updatePatientValue}
          updateNPI={this.props.updateNPI}
          updateLocationNPI={this.props.updateLocationNPI}
          updateLocationProviderValue={this.props.updateLocationProviderValue}
          removeServiceItem={this.props.removeServiceItem}
          updateServiceValue={this.props.updateServiceValue}
          addServiceItem={this.props.addServiceItem}
          handleSaveClick={this.handleSaveClick}
          handleSaveAndRepriceClick={this.handleSaveAndRepriceClick}
          handleDeleteClick={this.handleDeleteClick}
          handleCompleteAndSubmitClick={this.handleCompleteAndSubmit}
          isDataEnterer={isCurrentDataEnterer}
          toggleAddressIsEditable={this.props.toggleAddressIsEditable}
          toggleAddressVerified={this.props.toggleAddressVerified}
          toggleServiceLocationAddressIsEditable={this.props.toggleServiceLocationAddressIsEditable}
          toggleServiceLocationAddressVerified={this.props.toggleServiceLocationAddressVerified}
          handleNewClaimClick={this.handleNewClaim}
          validateClaim={this.handleValidateClaim}
          invalidateClaim={this.handleInvalidateClaim}
          isEdit={isEdit}
          invalidClaimId={this.props.invalidClaimId}
          handlePolicyNumChange={this.handlePolicyNumChange}
          updateClaimValue={this.props.updateClaimValue}
          toggleFileUpload={this.props.toggleFileUpload}
          selectedFile={selectedFile}
          claimType={claimType}
          handleFileUpload={this.handleFileUpload}
          onFileAndClaimTypeChange={this.handleFileAndClaimTypeChange}
        />
      </AppLayout>
    );
  }
}

NewClaims.defaultProps = {
  selectedClaim: {},
  totalPages: 0,
  search: {},
  selectedFile: null,
  claimType: null,
};
NewClaims.propTypes = {
  match: types.routerMatch.isRequired,
  totalPages: PropTypes.number,
  claims: PropTypes.arrayOf(types.claim).isRequired,
  search: types.search,
  plans: PropTypes.arrayOf(types.planInfo).isRequired,
  isEdit: PropTypes.bool.isRequired,
  invalidClaimId: PropTypes.bool.isRequired,
  selectedClaim: types.claim,
  currentUser: types.simpleUser.isRequired,
  history: types.history.isRequired,
  updateProviderValue: PropTypes.func.isRequired,
  updateNPI: PropTypes.func.isRequired,
  updateLocationNPI: PropTypes.func.isRequired,
  updateLocationProviderValue: PropTypes.func.isRequired,
  toggleAddressVerified: PropTypes.func.isRequired,
  toggleAddressIsEditable: PropTypes.func.isRequired,
  toggleServiceLocationAddressVerified: PropTypes.func.isRequired,
  toggleServiceLocationAddressIsEditable: PropTypes.func.isRequired,
  updateSubscriberValue: PropTypes.func.isRequired,
  updatePatientValue: PropTypes.func.isRequired,
  addServiceItem: PropTypes.func.isRequired,
  removeServiceItem: PropTypes.func.isRequired,
  updateServiceValue: PropTypes.func.isRequired,
  updateClaimValue: PropTypes.func.isRequired,
  getClaims: PropTypes.func.isRequired,
  getClaim: PropTypes.func.isRequired,
  deleteClaim: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  saveAndReprice: PropTypes.func.isRequired,
  // uploadClaimFile: PropTypes.func.isRequired,
  saveAndSubmit: PropTypes.func.isRequired,
  initialSave: PropTypes.func.isRequired,
  clearClaim: PropTypes.func.isRequired,
  getPlans: PropTypes.func.isRequired,
  updatePolicyNumber: PropTypes.func.isRequired,
  updateSubPlanID: PropTypes.func.isRequired,
  checkClaimAndRedirect: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
  claimType: PropTypes.string,
};

function mapStateToProps({ manualClaim, claims, user }) {
  return {
    selectedClaim: manualClaim.selectedClaim,
    isEdit: manualClaim.isEdit,
    claims: claims.claims,
    search: claims.search,
    currentUser: user.currentUser,
    invalidClaimId: manualClaim.invalidClaimId,
    plans: claims.plans,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProviderValue: (name, value) =>
      dispatch(updateProviderValue('selectedClaim.billProv', name, value)),
    updateLocationProviderValue: (name, value) =>
      dispatch(
        updateServiceLocationProviderValue('selectedClaim.claims[0].serviceLocation', name, value)
      ),
    updateNPI: value => dispatch(updateNPI('selectedClaim.billProv', value)),
    updateLocationNPI: value => dispatch(updateServiceLocationNPI(value)),
    toggleAddressVerified: () => dispatch(toggleAddressVerified('selectedClaim.billProv')),
    toggleAddressIsEditable: () => dispatch(toggleAddressIsEditable('selectedClaim.billProv')),

    toggleServiceLocationAddressIsEditable: () =>
      dispatch(toggleServiceLocationAddressIsEditable('selectedClaim.claims[0].serviceLocation')),
    toggleServiceLocationAddressVerified: () =>
      dispatch(toggleServiceLocationAddressVerified('selectedClaim.claims[0].serviceLocation')),

    updateSubscriberValue: (name, value) =>
      dispatch(updateIndivOrOrgValue('selectedClaim.claims[0].subscriber', name, value)),
    updatePatientValue: (name, value) =>
      dispatch(updateIndivOrOrgValue('selectedClaim.claims[0].patient', name, value)),

    addServiceItem: index => dispatch(addServiceItem('selectedClaim.claims[0].services', index)),
    removeServiceItem: index =>
      dispatch(removeServiceItem('selectedClaim.claims[0].services', index)),
    updateServiceValue: (index, name, value) =>
      dispatch(updateServiceValue('selectedClaim.claims[0].services', index, name, value)),
    updateClaimValue: (name, value) =>
      dispatch(updateClaimValue('selectedClaim.claims[0]', name, value)),
    getClaims: (filterGroup, currentPage, pageSize) =>
      dispatch(getClaims(filterGroup, currentPage, pageSize)),
    getClaim: id => dispatch(getFlatClaim(id)),
    deleteClaim: id => dispatch(deleteClaim(id)),
    save: (id, claim) => dispatch(saveClaim(id, claim)),
    hasErrors: claim => dispatch(validateClaimBeforeFileUpload(claim)),
    saveAndReprice: (id, claim, history) =>
      dispatch(saveClaimAndRedirect(id, claim, history, fgRepricing)),
    saveAndSubmit: (id, claim, history) =>
      dispatch(saveClaimAndSubmit(id, claim, history, fgRepricing)),
    // uploadClaimFile: (selectedFile, claimType)=>
    //   dispatch(uploadClaimFile(selectedFile, claimType)),
    initialSave: (id, claim, history) => dispatch(initialSaveClaim(id, claim, history)),
    clearClaim: () => dispatch(clearClaim()),

    getPlans: () => dispatch(getPlans()),
    updatePolicyNumber: (path, policyNum, sixDegTPAID, sixDegPlanID, plans) =>
      dispatch(updatePolicyNumber(path, policyNum, sixDegTPAID, sixDegPlanID, plans)),
    updateSubPlanID: (path, subPlanID) => dispatch(updateSubPlanID(path, subPlanID)),

    checkClaimAndRedirect: (id, history, status) =>
      dispatch(checkClaimAndRedirect(id, history, status)),
    showSnackbar: message => {
      dispatch(addSnackbar(message));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewClaims);
