import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { inflateString } from '@apps/shared/src/inflators';

import PopperSelect from '../../shared/components/PopperSelect';
import TermItemInputs from './inputs/TermItemInputs';
import TermComparison from './inputs/TermComparison';
import { conditionTypes, ruleTypes } from './termElements';

import { TermItem, HandleTermValueChange, ContractTerm } from '../types/contracts';
import { ClickHandler } from '../../shared/types/types';
import { capitalizeWords } from '../../shared/globals';

const useStyles = makeStyles({
  conditions: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1rem',
  },
});

type Props = {
  itemType: 'rule' | 'condition';
  selectedTermItems: TermItem[];
  currentValues: ContractTerm;
  handleChange: HandleTermValueChange;
  addTermItem: (newItem: TermItem, index: number) => void;
  removeTermItem: (index: number) => ClickHandler;
  cancelTerm: VoidFunction;
};

export default function TermRulesOrConditions({
  itemType,
  selectedTermItems,
  currentValues,
  handleChange,
  addTermItem,
  removeTermItem,
  cancelTerm,
}: Props): JSX.Element {
  const classes = useStyles();

  const isRulesType = itemType === 'rule';
  const termOptions = isRulesType ? ruleTypes : conditionTypes;
  const termItemOptions = termOptions.filter(
    termOption => !(selectedTermItems as string[]).includes(termOption.value)
  );

  const formattedType = capitalizeWords(itemType);
  const formattedPluralType = formattedType.concat('s');
  return (
    <div className={classes.conditions}>
      <Typography variant="h6">{formattedPluralType}</Typography>
      {isRulesType && (
        <TermComparison
          visible={selectedTermItems.length > 1}
          currentValue={inflateString(currentValues.comparisonType)}
          handleChange={handleChange}
        />
      )}

      <TermItemInputs
        itemType={itemType}
        selectedTermItems={selectedTermItems}
        currentValues={currentValues}
        handleChange={handleChange}
        removeTermItem={removeTermItem}
        cancelTerm={cancelTerm}
      />

      <PopperSelect
        visible={termItemOptions.length > 1}
        options={termItemOptions}
        setOption={addTermItem}
        buttonVariant="outlined"
        buttonText={`Add ${formattedType}`}
      />
    </div>
  );
}
