import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, RadioGroup, Radio, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { TPA, PlanElection } from './types/plans';
import CensusNames from './CensusNames';

const useStyles = makeStyles({
  planNames: {
    marginBottom: '20px',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem',
    gap: '2rem',
  },
});

type ParentProps = {
  masterPlanID: number;
  masterPlanName: string;
  censusNames: string[];
  sfAccountID: string;
  status: string;
  planSelection: boolean;
  expandDetails: boolean;
  planElections: Record<number | string, PlanElection>;
  allTPAs: Record<number, TPA>;
  sixDegTPAID: number;
};

type Props = ParentProps;

export default function PlanNames({
  masterPlanID,
  masterPlanName,
  sfAccountID,
  status,
  planSelection,
  expandDetails,
  planElections,
  allTPAs,
  sixDegTPAID,
  censusNames,
}: Props): JSX.Element {
  const classes = useStyles();
  const selectedValue = planSelection ? '1' : '0';

  const isAllPlanElectionsTermed = (
    planElections: Record<number | string, PlanElection>
  ): boolean => {
    return Object.values(planElections).every(election => election.stagename === 'Termed');
  };

  // Construct Salesforce Opportunity link
  const sfAccountLink = `https://6degreehealth.lightning.force.com/lightning/r/Account/${sfAccountID}/view`;

  return (
    <div className={classes.planNames}>
      <div className={classes.head}>
        <Typography variant="body1" color="textSecondary">
          TPA : {allTPAs[sixDegTPAID] ? allTPAs[sixDegTPAID].tpaName : 'Unknown TPA'}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Status:
          {isAllPlanElectionsTermed(planElections) ? 'Termed' : status || 'Unknown Status'}
        </Typography>
      </div>
      <Typography variant="h5" component="h2" style={{ color: '#74b7fc', fontWeight: 600 }}>
        <Link href={sfAccountLink} target="_blank">
          {masterPlanName}
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Master Plan ID: {masterPlanID}
      </Typography>
      {expandDetails && (
        <div className={classes.body}>
          <Typography variant="body2" color="textSecondary">
            SF Account ID
            <div>{sfAccountID}</div>
          </Typography>

          <div>
            <Typography variant="body2" color="textSecondary">
              Plan Selection
            </Typography>
            <RadioGroup aria-label="plan-selection" name="plan-selection" value={selectedValue}>
              <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label="Census Names"
              />
              <FormControlLabel value="1" control={<Radio color="primary" />} label="Sub Plan ID" />
            </RadioGroup>
          </div>
          {censusNames.length > 0 && <CensusNames masterCensus={censusNames || []} />}
        </div>
      )}
    </div>
  );
}
