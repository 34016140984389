import React, { useState, ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { colors } from '@apps/shared/src/style';
import TextInput from '../../shared/components/TextInput';
import { InputChangeHandler } from '../../shared/types/types';
import DateInput from '../../manualClaim/DateInput';
import { PlanElection, TPA, MasterPlan } from './types/plans';
import { zeroDate, infinityDate, zeroPlanElection } from './plansReducer';
import { checkDate } from '../../shared/validators';

const useStyles = makeStyles({
  formContainer: {
    padding: '3rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '2rem',
  },
  root: {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
    margin: '1rem',
  },
  head: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '13px',
  },
  formHeader: {
    marginTop: '20px',
    marginBottom: '15px',
  },
  bottomButton: {
    display: 'flex',
    gap: '1rem',
  },
  addButton: {
    backgroundColor: colors.green,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.greenMuted,
    },
    padding: '5px',
    margin: '5px',
    minWidth: '0px',
  },
  alertText: {
    color: colors.red,
    fontSize: '12px',
    textAlign: 'left',
    wordSpacing: '0.2rem',
  },
  select: {
    minWidth: '200px',
  },
  censusChip: {
    margin: '5px',
  },
  bottom: {
    padding: '1rem 3rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type ParentProps = {
  localMasterPlan: MasterPlan;
  planElection: PlanElection;
  updateLocalPlanElection: (updatedElection: PlanElection) => void;
  handlePlanElectionSave: (uniqueIdentifier: string | number) => void;
  openPlanElectionDialog: boolean;
  setOpenPlanElectionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  planElectionErrorText: string;
  setPlanElectionErrorText: React.Dispatch<React.SetStateAction<string>>;
  allTPAs: Record<number, TPA>;
  isNewPlanElection: boolean;
  handleCancel: () => void;
  handleSnackBar: (message: string) => void;
};

export default function PlanElectionForm({
  localMasterPlan,
  planElection,
  allTPAs,
  isNewPlanElection,
  openPlanElectionDialog,
  planElectionErrorText,
  setPlanElectionErrorText,
  updateLocalPlanElection,
  handleSnackBar,
  handlePlanElectionSave,
  handleCancel,
}: ParentProps): JSX.Element {
  const classes = useStyles();
  const [newCensusName, setNewCensusName] = useState('');
  const [newSubPlanID, setNewSubPlanID] = useState('');

  const handleInputChange =
    (field: keyof PlanElection) =>
    (e: ChangeEvent<HTMLInputElement | { value: unknown }>): void => {
      if (field === 'groupidoncard' || field === 'planid') {
        updateLocalPlanElection({
          ...planElection,
          [field]: (e.target as HTMLInputElement).value.toUpperCase(),
        });
      } else {
        updateLocalPlanElection({
          ...planElection,
          [field]: e.target.value,
        });
      }
    };

  const handleNumberValueChange =
    (field: keyof PlanElection) =>
    (e: ChangeEvent<HTMLInputElement | { value: unknown }>): void => {
      updateLocalPlanElection({
        ...planElection,
        [field]: e.target.value,
      });
    };

  const handleCheckboxChange =
    (field: keyof PlanElection) =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      updateLocalPlanElection({
        ...planElection,
        [field]: e.target.checked,
      });
    };

  const handleTPAIDChange = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    if (typeof e.target.value === 'number') {
      const integerID = e.target.value;

      updateLocalPlanElection({
        ...planElection,
        sixdegtpaid: integerID,
      });
    }
  };
  const handleSubPlanIDAddClick = (): void => {
    if (newSubPlanID.length < 1) {
      setPlanElectionErrorText('Sub Plan ID must not be empty');
      return;
    }
    if (planElection.censusnames && planElection.censusnames.some(name => name === newSubPlanID)) {
      setPlanElectionErrorText('No duplicate Sub Plan ID allowed');
      return;
    }
    if (planElection.subPlanID) {
      const newSubPlanIDs = [...planElection.subPlanID];
      if (!newSubPlanIDs.includes(newSubPlanID)) {
        newSubPlanIDs.push(newSubPlanID);
      }
      updateLocalPlanElection({
        ...planElection,
        subPlanID: newSubPlanIDs,
      });
    }
    setNewSubPlanID('');
  };

  const handleSubPlanIDRemoveClick = (index: number) => (): void => {
    const newSubPlanIDs = [...(planElection.subPlanID || [])];
    newSubPlanIDs.splice(index, 1);
    updateLocalPlanElection({
      ...planElection,
      subPlanID: newSubPlanIDs,
    });
  };

  const handleSubPlanIDUpdate: InputChangeHandler = e => {
    setPlanElectionErrorText('');
    setNewSubPlanID(e.target.value.toUpperCase());
  };

  const handleCensusNameUpdate: InputChangeHandler = e => {
    setPlanElectionErrorText('');
    setNewCensusName(e.target.value.toUpperCase());
  };

  const handleAddClick = (): void => {
    if (newCensusName.length < 1) {
      setPlanElectionErrorText('Census name must not be empty');
      return;
    }
    if (planElection.censusnames && planElection.censusnames.some(name => name === newCensusName)) {
      setPlanElectionErrorText('No duplicate census names allowed');
      return;
    }
    if (planElection.censusnames) {
      const newCensusNames = [...planElection.censusnames];
      if (!newCensusNames.includes(newCensusName)) {
        newCensusNames.push(newCensusName);
      }
      updateLocalPlanElection({
        ...planElection,
        censusnames: newCensusNames,
      });
    }
    setNewCensusName('');
  };

  const handleRemoveClick = (index: number) => (): void => {
    if (planElection.censusnames) {
      const newCensusNames = [...planElection.censusnames];
      newCensusNames.splice(index, 1);
      updateLocalPlanElection({
        ...planElection,
        censusnames: newCensusNames,
      });
    }
  };

  function updateDateField(
    field: 'startdate' | 'termdate' | 'runoutenddate',
    value: number | string,
    handleSnackBar: (message: string) => void
  ): void {
    const [isValid, parsedDate] = checkDate(value, true);
    const updatedPlanElection = { ...planElection };

    if (isValid) {
      updatedPlanElection[field] = parsedDate;
    } else {
      updatedPlanElection[field] = field === 'termdate' ? infinityDate : zeroDate;
      if (value !== updatedPlanElection[field]) {
        handleSnackBar(`Invalid Date: ${value}`);
      }
    }

    updateLocalPlanElection(updatedPlanElection);
  }

  return (
    <Dialog open={openPlanElectionDialog} onClose={handleCancel} maxWidth="md">
      <div className={classes.root}>
        {/* Stage Name */}
        <FormControl className={classes.select} style={{ margin: '3rem 1rem 0 3rem' }}>
          <InputLabel shrink>
            Stage Name <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <Select value={planElection.stagename} onChange={handleInputChange('stagename')}>
            <MenuItem value="Live Plan">Live Plan</MenuItem>
            <MenuItem value="Termed">Termed</MenuItem>
          </Select>
        </FormControl>

        {/* TPA  */}
        <FormControl style={{ margin: '3rem 1rem 0 3rem' }}>
          <InputLabel shrink>
            TPA <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <Select
            className={classes.select}
            autoWidth
            onChange={handleTPAIDChange}
            value={planElection.sixdegtpaid}
          >
            {Object.entries(allTPAs).map(([id, tpa]) => (
              <MenuItem key={id} value={tpa.sixDegTPAID}>
                {tpa.tpaName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={classes.formContainer}>
          {/* Election Name */}
          <TextField
            label={
              <>
                Election Name <span style={{ color: 'red' }}>*</span>
              </>
            }
            InputLabelProps={{
              shrink: true,
            }}
            value={planElection.planname}
            onChange={handleInputChange('planname')}
          />

          {/* Plan ID */}
          <TextField
            label={
              <>
                Plan ID <span style={{ color: 'red' }}>*</span>
              </>
            }
            InputLabelProps={{
              shrink: true,
            }}
            value={planElection.planid}
            onChange={handleInputChange('planid')}
          />

          {/* Start Time */}
          <div>
            <Typography className={classes.head}>
              Start Date<span style={{ color: 'red' }}>*</span>
            </Typography>
            <DateInput
              name="startdate"
              onValueChange={(value: any) => updateDateField('startdate', value, handleSnackBar)}
              value={planElection.startdate}
            />
          </div>

          {/* Term Time */}
          <div>
            <Typography className={classes.head}>
              Term Date <span style={{ color: 'red' }}>*</span>
            </Typography>
            <DateInput
              name="termdate"
              onValueChange={(value: any) => updateDateField('termdate', value, handleSnackBar)}
              value={planElection.termdate}
            />
          </div>

          {/* Facility */}
          <TextField
            label="Facility"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={planElection.facilitymultiplier}
            onChange={handleNumberValueChange('facilitymultiplier')}
          />

          {/* Professional */}
          <TextField
            label="Professional"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={planElection.professionalmultiplier}
            onChange={handleNumberValueChange('professionalmultiplier')}
          />

          {/* Drug */}
          <TextField
            label="Drug"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={planElection.drugmultiplier}
            onChange={handleNumberValueChange('drugmultiplier')}
          />

          {/* Anesthesia */}
          <TextField
            label="Anesthesia"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            value={planElection.anesthesiamultiplier}
            onChange={handleNumberValueChange('anesthesiamultiplier')}
          />

          {/* Medivi Plan Access */}
          <FormControl className={classes.select}>
            <InputLabel>
              Medivi Plan Access <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Select
              value={planElection.mediviplanaccess}
              onChange={handleInputChange('mediviplanaccess')}
            >
              <MenuItem value="Full Access">Full Access</MenuItem>
              <MenuItem value="Limited Access">Limited Access</MenuItem>
              <MenuItem value="No Access">No Access</MenuItem>
            </Select>
          </FormControl>

          {/* Group ID on Card */}
          <TextField
            label="Group ID on Card"
            InputLabelProps={{
              shrink: true,
            }}
            value={planElection.groupidoncard}
            onChange={handleInputChange('groupidoncard')}
          />

          {/* Proplus */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography className={classes.head}>
              ProPlus <span style={{ color: 'red' }}>*</span>
            </Typography>
            <FormControl>
              <Select
                /* eslint-disable no-nested-ternary */
                value={
                  isNewPlanElection
                    ? planElection.proplus === ''
                      ? ''
                      : planElection.proplus
                      ? 'true'
                      : 'false'
                    : planElection.proplus === ''
                    ? 'false'
                    : planElection.proplus
                    ? 'true'
                    : 'false'
                }
                /* eslint-enable no-nested-ternary */
                onChange={({ target: { value } }) => {
                  updateLocalPlanElection({
                    ...planElection,
                    proplus: value === 'true',
                  });
                }}
                inputProps={{ 'aria-label': 'proplus' }}
              >
                <MenuItem value="" />
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Run In */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.head}>Run In</Typography>
            <FormControlLabel
              control={
                <Checkbox checked={planElection.runin} onChange={handleCheckboxChange('runin')} />
              }
              label=""
            />
          </div>

          {/* RunoutType */}
          {/* {planElection.stagename === 'Run Out' && (
            <div>
              <Typography className={classes.head} variant="body2" color="textSecondary">
                Run Out Type
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={planElection.indefiniterunout}
                      onChange={e =>
                        updateLocalPlanElection({
                          ...planElection,
                          indefiniterunout: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Indefinitely reprice claims with DOS within contracted period"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={planElection.repriceforrunoutperiod}
                      onChange={e =>
                        updateLocalPlanElection({
                          ...planElection,
                          repriceforrunoutperiod: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Reprice claims according to run out period"
                />
              </div>
            </div>
          )}

          {planElection.stagename === 'Run Out' && planElection.repriceforrunoutperiod && (
            <div>
              <Typography className={classes.head}>Runout End Date</Typography>
              <DateInput
                name="runoutenddate"
                onValueChange={(value: any) =>
                  updateDateField('runoutenddate', value, handleSnackBar)
                }
                value={planElection.runoutenddate}
              />
            </div>
          )} */}

          <FormControl className={classes.select}>
            <InputLabel>837 Invoice</InputLabel>
            <Select
              defaultValue="none"
              value={planElection.invoicingtype || 'none'}
              onChange={handleInputChange('invoicingtype')}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="% Saving">% Saving</MenuItem>
              <MenuItem value="% Billed">% Billed</MenuItem>
            </Select>
          </FormControl>

          {/* Percent Savings */}
          {(planElection.invoicingtype === '% Saving' ||
            planElection.invoicingtype === '% Billed') && (
            <TextField
              label={`Percent of ${
                planElection.invoicingtype === '% Saving' ? 'Savings' : 'Billed'
              } Fee`}
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              value={planElection.percentsavings}
              onChange={handleNumberValueChange('percentsavings')}
            />
          )}

          {/* Send 837 Invoice */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography className={classes.head}>Send 837 Invoice</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={planElection.send837invoice}
                  onChange={handleCheckboxChange('send837invoice')}
                />
              }
              label=""
            />
          </div> */}

          {/* Facility Invoicing */}
          {planElection.invoicingtype !== 'none' && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography className={classes.head}>Facility Invoicing</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={planElection.facilityinvoicing}
                    onChange={handleCheckboxChange('facilityinvoicing')}
                  />
                }
                label=""
              />
            </div>
          )}

          {/* Professional Invoicing */}
          {planElection.invoicingtype !== 'none' && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography className={classes.head}>Professional Invoicing</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={planElection.professionalinvoicing}
                    onChange={handleCheckboxChange('professionalinvoicing')}
                  />
                }
                label=""
              />
            </div>
          )}

          {/* Bypasseligibility */}
          {!localMasterPlan.planSelection && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography className={classes.head}>Bypasseligibility</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={planElection.bypasseligibility}
                    onChange={handleCheckboxChange('bypasseligibility')}
                  />
                }
                label=""
              />
            </div>
          )}

          <div>
            <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
              {/* TBC Threshold */}
              <Typography className={classes.head}>Coding Review threshold</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={planElection.tbcthreshold}
                    onChange={handleCheckboxChange('tbcthreshold')}
                  />
                }
                label=""
              />
            </div>

            {planElection.tbcthreshold && (
              <TextField
                label=""
                type="number"
                value={planElection.threshold}
                onChange={handleNumberValueChange('threshold')}
              />
            )}
          </div>

          {/* Medivi Message */}
          <div>
            <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
              <Typography className={classes.head}>Display Medivi Message</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={planElection.displaymedivimessage}
                    onChange={handleCheckboxChange('displaymedivimessage')}
                  />
                }
                label=""
              />
            </div>
            {planElection.displaymedivimessage && (
              <TextareaAutosize
                aria-label="Medivi Message"
                minRows={4}
                style={{ minWidth: '400px' }}
                value={planElection.medivimessage}
                onChange={handleInputChange('medivimessage')}
              />
            )}
          </div>
          {!localMasterPlan.planSelection && (
            <div>
              {planElection.censusnames && planElection.censusnames.length > 0 && (
                <Typography>
                  Census Names<span style={{ color: 'red' }}>*</span>
                </Typography>
              )}
              {planElection.censusnames &&
                planElection.censusnames.map((name, i) => {
                  return (
                    <Chip
                      key={name}
                      label={name}
                      className={classes.censusChip}
                      onDelete={handleRemoveClick ? handleRemoveClick(i) : undefined}
                    />
                  );
                })}
              <Typography>Add Census Names:</Typography>
              <TextInput
                aria-label="Census Name"
                value={newCensusName}
                onChange={handleCensusNameUpdate}
              />
              <IconButton
                className={classes.addButton}
                onClick={handleAddClick}
                data-testid="add-census-name-button"
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          )}
          {localMasterPlan.planSelection && (
            <div>
              {planElection.subPlanID && planElection.subPlanID.length > 0 && (
                <Typography>
                  Sub Plan ID<span style={{ color: 'red' }}>*</span>
                </Typography>
              )}
              {planElection.subPlanID &&
                planElection.subPlanID.map((name, i) => {
                  return (
                    <Chip
                      key={name}
                      label={name}
                      className={classes.censusChip}
                      onDelete={
                        handleSubPlanIDRemoveClick ? handleSubPlanIDRemoveClick(i) : undefined
                      }
                    />
                  );
                })}
              <Typography>Add Sub Plan ID:</Typography>
              <TextInput
                aria-label="Sub Plan ID"
                value={newSubPlanID}
                onChange={handleSubPlanIDUpdate}
              />
              <IconButton
                className={classes.addButton}
                onClick={handleSubPlanIDAddClick}
                data-testid="add-sub-plan-id-button"
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          )}
        </div>
        <div className={classes.bottom}>
          <div className={classes.alertText}>{planElectionErrorText}</div>
          <div className={classes.bottomButton}>
            <Button size="large" variant="contained" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() =>
                handlePlanElectionSave(
                  planElection.sixDegPlanID !== 0 && planElection.FEUniqueId === ''
                    ? planElection.sixDegPlanID
                    : planElection.FEUniqueId
                )
              }
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
